

// Импорт компонентов
// import UiChart from "ui-chart/src/ui-chart.vue";

// Tirscript-bootstrap
import BsCol from "tirscript3-bootstrap/src/bs-col.vue";
import BsRow from "tirscript3-bootstrap/src/bs-row.vue";
import BsContainer from "tirscript3-bootstrap/src/bs-container.vue";
import BsFormGroup from "tirscript3-bootstrap/src/bs-form-group.vue";
import BsLabel from "tirscript3-bootstrap/src/bs-container.vue";

import Tirscript3Input from "tirscript3-input/src/tirscript3-input.vue";
import Tirscript3Button from "tirscript3-button/src/tirscript3-button.vue";
// import Tirscript3ListViewItemCheck from "tirscript3-list-view/src/tirscript3-list-view-item-check.vue";
import Tirscript3ListViewItem from "tirscript3-list-view/src/tirscript3-list-view-item.vue";
// import Tirscript3ListViewTabs from "tirscript3-list-view/src/tirscript3-list-view-tabs.vue";
import Tirscript3ListView from "tirscript3-list-view/src/tirscript3-list-view.vue";
import Tirscript3Dropdown from "tirscript3-dropdown/src/tirscript3-dropdown.vue";
import Tirscript3Checkbox from "tirscript3-checkbox/src/tirscript3-checkbox.vue";
// Tirscript3-table
import Tirscript3Pagination from "tirscript3-table/src/tirscript3-pagination.vue";
import Tirscript3TableBodyItem from "tirscript3-table/src/tirscript3-table-body-item.vue";
import Tirscript3Table from "tirscript3-table/src/tirscript3-table.vue";
import Tirscript3TableFilter from "tirscript3-table/src/tirscript3-table-filter.vue";
import Tirscript3TableFooterItem from "tirscript3-table/src/tirscript3-table-footer-item.vue";
import Tirscript3TableHeaderItem from "tirscript3-table/src/tirscript3-table-header-item.vue";
import Tirscript3TableHeader from "tirscript3-table/src/tirscript3-table-header.vue";
// Tirscript3-date-picker
import Tirscript3DatePicker from "tirscript3-date-picker/src/tirscript3-date-picker.vue";
import Tirscript3DateTime from "tirscript3-date-picker/src/tirscript3-date-time.vue";
import Tirscript3Days from "tirscript3-date-picker/src/tirscript3-days.vue";
import Tirscript3Hours from "tirscript3-date-picker/src/tirscript3-hours.vue";
import Tirscript3Minutes from "tirscript3-date-picker/src/tirscript3-minutes.vue";
import Tirscript3Months from "tirscript3-date-picker/src/tirscript3-months.vue";
import Tirscript3Years from "tirscript3-date-picker/src/tirscript3-years.vue";

import Tirscript3ModalWindow from "tirscript3-modal-window/src/tirscript3-modal-window.vue";

import Tirscript3FileInput from "tirscript3-file-input/src/tirscript3-file-input.vue";

import Tirscript3Loading from "tirscript3-loading/src/tirscript3-loading.vue";
import Tirscript3Scroll from "tirscript3-scroll/src/tirscript3-scroll.vue";
import Tirscript3BoxMenu from "tirscript3-box-menu/src/tirscript3-box-menu.vue";

import SelectSource from "@/views/components/filter-component/select-source-component.vue";
import SelectInterval from "@/views/components/filter-component/select-interval-component.vue";

import FilterComponent from "@/views/components/filter-component/filter-component.vue";

import TableHeaderItemComponent from "@/views/components/table-header-item.vue";
import { createApp } from "vue";

export default (app: any) => {
  // Подключение компонентов
  // app.component("ui-chart", UiChart);
  // Tirscript-bootstrap
  app.component("bs-row", BsRow);
  app.component("bs-col", BsCol);
  app.component("bs-container", BsContainer);

  app.component("tirscript3-input", Tirscript3Input);
  app.component("tirscript3-button", Tirscript3Button);
  // app.component("tirscript3-list-view-item-check", Tirscript3ListViewItemCheck)
  app.component("tirscript3-list-view-item", Tirscript3ListViewItem);
  // app.component("tirscript3-list-view-tabs", Tirscript3ListViewTabs)
  app.component("tirscript3-list-view", Tirscript3ListView);
  app.component("tirscript3-dropdown", Tirscript3Dropdown);
  app.component("tirscript3-checkbox", Tirscript3Checkbox);
  // Tirscript3-table
  app.component("tirscript3-pagination", Tirscript3Pagination);
  app.component("tirscript3-table-body-item", Tirscript3TableBodyItem);
  app.component("tirscript3-table", Tirscript3Table);
  app.component("tirscript3-table-filter", Tirscript3TableFilter);
  app.component("tirscript3-table-footer-item", Tirscript3TableFooterItem);
  app.component("tirscript3-table-footer-item", Tirscript3TableFooterItem);
  app.component("tirscript3-table-header-item", Tirscript3TableHeaderItem);
  app.component("tirscript3-table-header", Tirscript3TableHeader);
  // Tirscript-date-picker
  app.component("tirscript3-date-picker", Tirscript3DatePicker);
  app.component("tirscript3-date-time", Tirscript3DateTime);
  app.component("tirscript3-days", Tirscript3Days);
  app.component("tirscript3-hours", Tirscript3Hours);
  app.component("tirscript3-minutes", Tirscript3Minutes);
  app.component("tirscript3-months", Tirscript3Months);
  app.component("tirscript3-years", Tirscript3Years);

  app.component("tirscript3-modal-window", Tirscript3ModalWindow);

  app.component("tirscript3-loading", Tirscript3Loading);
  app.component("tirscript3-scroll", Tirscript3Scroll);
  app.component("tirscript3-box-menu", Tirscript3BoxMenu);

  app.component("select-source-component", SelectSource);
  app.component("select-interval-component", SelectInterval);

  app.component("filter-component", FilterComponent);

  app.component("table-header-item", TableHeaderItemComponent)
};
