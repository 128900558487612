import moment from "moment";

export default class FilterModel {
  source: number;
  interval: number = 1000;
  DateFrom: Date = new Date();
  DateTo: Date = new Date();
  LogType: number = null;
  LogLevel: number = null;
  Url: string;
  SessionToken: string;
  Ip: string;
  UserId: string = null;
  ConnectionId: string = null;
  RequestId: string;
  controllerMethod: number;
  EndpointId: number;
  ProjectLogGroupId: number = null;
  HardwareLogsType: number = null;
  OnlyActiveRequests: boolean;
  LoggerName: string;
  ShortMessage: string;
  SkipPage: number = 0;
  TakePage: number = 20;

  constructor(obj?: Partial<FilterModel>) {
    if (obj) {
      Object.assign(this, obj);
    }
    this.DateFrom = this.getUTC(10);
    this.DateTo = this.getUTC()
  }

  update(model: FilterModel) {
    this.source = model.source;
    this.interval = model.interval;
    this.DateFrom = model.DateFrom;
    this.DateTo = model.DateTo;
    this.LogType = model.LogType;
    this.LogLevel = model.LogLevel;
    this.Url = model.Url;
    this.SessionToken = model.SessionToken;
    this.Ip = model.Ip;
    this.UserId = model.UserId;
    this.ConnectionId = model.ConnectionId;
    this.RequestId = model.RequestId;
    this.controllerMethod = model.controllerMethod;
    this.EndpointId = model.EndpointId;
    this.ProjectLogGroupId = model.ProjectLogGroupId;
    this.HardwareLogsType = model.HardwareLogsType;
    this.OnlyActiveRequests = model.OnlyActiveRequests;
    this.LoggerName = model.LoggerName;
    this.ShortMessage = model.ShortMessage;
    this.SkipPage = model.SkipPage;
    this.TakePage = model.TakePage;
    // this.ConnectionId = model.ConnectionId;
    // this.Ip = model.Ip;
    // this.LogLevel = model.LogLevel;
    // this.LogType = model.LogLevel;
    // (this.HardwareLogsType = model.HardwareLogsType),
    //   (this.RequestId = model.RequestId);
    // this.SessionToken = model.SessionToken;
    // this.Url = model.SessionToken;
    // this.UserId = model.UserId;
    // this.source = model.source;
    // this.EndpointId = model.EndpointId;
    // this.controllerMethod = null;
  }
  //- 2 часа от текущего времени
  // getNewTime(min: number = 2): Date {
  //   let date = new Date();
  //   return new Date(date.getTime() - 120 * 60 * 1000); //-2 часа
  // }
  getNewTime(min: number = 2): Date {
    let date = new Date();
    return new Date(date.getTime());
  }

  getUTC(minusMinutes = 0) {
    let date = new Date()
    let timezoneHour = new Date().getTimezoneOffset() / 60
    date = moment(date).add("hours", timezoneHour).toDate()
    date.setMinutes(date.getMinutes() - minusMinutes)
    return date;
  }
}
