import { WebApiService } from './plugins/webApiService';
import EndpointService from './logger-micro-service/services/ws/EndpointService';
import EndpointsStatisticsService from './logger-micro-service/services/ws/EndpointsStatisticsService';
import IndicatorsService from './logger-micro-service/services/ws/IndicatorsService';
import LogGetterService from './logger-micro-service/services/ws/LogGetterService';
import LogLevelService from './logger-micro-service/services/ws/LogLevelService';
import LogTypeService from './logger-micro-service/services/ws/LogTypeService';
import ProjectLogGroupService from './logger-micro-service/services/ws/ProjectLogGroupService';
import TopEndpointLogsService from './logger-micro-service/services/ws/TopEndpointLogsService';
import TopWebLogsService from './logger-micro-service/services/ws/TopWebLogsService';
import WebLogGetterService from './logger-micro-service/services/ws/WebLogGetterService';
import WebSocketGetterService from './logger-micro-service/services/ws/WebSocketGetterService';
import AnalyticEndpointsService from './analytic-endpoints/services/AnalyticEndpointsService';
import UserService from './users/services/UserService';
import AnalyticLogService from './analytic-logs/services/AnalyticLogService';
import LoggerDictionaryService from './logger-dictionary/services/LoggerDictionaryService';
import AnalyticUsersService from './analytic-users/service/AnalyticUsersService'

export default class apiDataSource {
	webApiService: WebApiService;
	EndpointService: EndpointService;
	EndpointsStatisticsService: EndpointsStatisticsService;
	IndicatorsService: IndicatorsService;
	LogGetterService: LogGetterService;
	LogLevelService: LogLevelService;
	LogTypeService: LogTypeService;
	ProjectLogGroupService: ProjectLogGroupService;
	TopEndpointLogsService: TopEndpointLogsService;
	TopWebLogsService: TopWebLogsService;
	WebLogGetterService: WebLogGetterService;
	WebSocketGetterService: WebSocketGetterService;
	AnalyticEndpointsService: AnalyticEndpointsService;
	UserService: UserService;
	AnalyticLogService: AnalyticLogService;
	LoggerDictionaryService: LoggerDictionaryService;
	AnalyticUsersService: AnalyticUsersService;
	constructor(webApiUrl: string, cookieName: string = "Auth-Token") {
		this.webApiService = new WebApiService(webApiUrl,cookieName);
		this.EndpointService = new EndpointService(this.webApiService);
		this.EndpointsStatisticsService = new EndpointsStatisticsService(this.webApiService);
		this.IndicatorsService = new IndicatorsService(this.webApiService);
		this.LogGetterService = new LogGetterService(this.webApiService);
		this.LogLevelService = new LogLevelService(this.webApiService);
		this.LogTypeService = new LogTypeService(this.webApiService);
		this.ProjectLogGroupService = new ProjectLogGroupService(this.webApiService);
		this.TopEndpointLogsService = new TopEndpointLogsService(this.webApiService);
		this.TopWebLogsService = new TopWebLogsService(this.webApiService);
		this.WebLogGetterService = new WebLogGetterService(this.webApiService);
		this.WebSocketGetterService = new WebSocketGetterService(this.webApiService);
		this.AnalyticEndpointsService = new AnalyticEndpointsService(this.webApiService);
		this.UserService = new UserService(this.webApiService);
		this.AnalyticLogService = new AnalyticLogService(this.webApiService);
		this.LoggerDictionaryService = new LoggerDictionaryService(this.webApiService);
		this.AnalyticUsersService = new AnalyticUsersService(this.webApiService);
	}

}
