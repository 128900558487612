
import { Events } from "@dataSource/plugins/events";
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import NotificationOptions, {
  EnumNotificationType,
} from "./model/notificationOptions";
@Options<UiNotificaton>({
  props: {
    eventBus: Events,
  },
})
export default class UiNotificaton extends Vue {
  // setup() {
  //   let test = ref(0);
  //   return { test };
  // }
  notificationsSuccess = new Array<NotificationOptions>();
  notificationsError = new Array<NotificationOptions>();
  notifications = new Array<NotificationOptions>();
  eventBus = new Events<NotificationOptions>();
  message = "";
  type = "";
  // @ViewChild('wrapper', { static: true }) wrapperEl;
  heightEl = 75;
  offset = 16;
  created() {
    console.log("created ui-notification");
    this.eventBus.on(this.show);
  }
  getClassByType(type: EnumNotificationType) {
    switch (type) {
      case EnumNotificationType.Error:
        return "notification-error";
      case EnumNotificationType.Success:
        return "notification-success";
    }
  }
  getIconClassByType(type: EnumNotificationType) {
    switch (type) {
      case EnumNotificationType.Error:
        return "icon-close";
      case EnumNotificationType.Success:
        return "icon-check";
    }
  }
  getAddedElementHeight(type: EnumNotificationType): number {
    // const cssClass =
    //   type === EnumNotificationType.Error
    //     ? "notification-error"
    //     : "notification-success";
    const el = this.$refs.wrapper as HTMLElement;
    // const childs = el.querySelectorAll("." + cssClass);
    const childs = el.querySelectorAll(".notification");
    if (childs && childs.length > 0) {
      return childs[childs.length - 1].clientHeight + this.offset;
    }
    return 0;
  }
  show(ev: NotificationOptions): void {
    // switch (ev.type) {
    //   case EnumNotificationType.Error:
    //     this.notificationsError.push(ev);
    //     break;
    //   case EnumNotificationType.Success:
    //     this.notificationsSuccess.push(ev);
    //     break;
    // }
    this.notifications.push(ev);
    setTimeout(() => {
      this.changePositionArray(ev, this.getAddedElementHeight(ev.type));
    });

    setTimeout(this.removeArray.bind(this, ev), ev.timeOut);
  }
  changePositionArray(ev: NotificationOptions, height: number): void {
    this.notifications.filter((x) => x !== ev).map((x) => (x.top += height));
    // this.notificationsError.filter(x => x !== ev).map(x => (x.top += height));
    // this.notificationsSuccess.filter(x => x !== ev).map(x => (x.top += height));
  }
  removeArray(notification: NotificationOptions): void {
    // switch (notification.type) {
    //   case EnumNotificationType.Error:
    //     this.notificationsError = this.notificationsError.filter(
    //       x => x !== notification
    //     );
    //     break;
    //   case EnumNotificationType.Success:
    //     this.notificationsSuccess = this.notificationsSuccess.filter(
    //       x => x !== notification
    //     );
    //     break;
    // }

    this.notifications = this.notifications.filter((x) => {
      x !== notification;
    });
  }
}
