import { WebApiService } from '../../plugins/webApiService'
import { Events } from '../../plugins/events'
import EventToAsyncDecorator from '../../plugins/eventToAsyncDecorator'
import GetProjectLogGroupsRequest from '../../logger-dictionary/i-services/models/request/GetProjectLogGroupsRequest';
import GetEndpointsRequest from '../../logger-dictionary/i-services/models/request/GetEndpointsRequest';
import GetEndpointRequest from '../../logger-dictionary/i-services/models/request/GetEndpointRequest';
import WsResponseModelT from '../../schema-builder-server/models/WsResponseModelT';
import GetProjectLogGroupsResponse from '../../logger-dictionary/i-services/models/response/GetProjectLogGroupsResponse';
import GetEndpointsResponse from '../../logger-dictionary/i-services/models/response/GetEndpointsResponse';
import Endpoint from '../../logger-micro-service/data-models/endpoints/Endpoint';

export default class LoggerDictionaryService {
	webApiService: WebApiService;
	GetProjectLogGroupsEvent = new Events<WsResponseModelT<GetProjectLogGroupsResponse>>();
	GetEndpointsEvent = new Events<WsResponseModelT<GetEndpointsResponse>>();
	GetEndpointEvent = new Events<WsResponseModelT<Endpoint>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('LoggerDictionaryService', 'GetProjectLogGroups', (res: WsResponseModelT<GetProjectLogGroupsResponse>) => {
			self.GetProjectLogGroupsEvent.trigger(res);
		});
		webApiService.on('LoggerDictionaryService', 'GetEndpoints', (res: WsResponseModelT<GetEndpointsResponse>) => {
			self.GetEndpointsEvent.trigger(res);
		});
		webApiService.on('LoggerDictionaryService', 'GetEndpoint', (res: WsResponseModelT<Endpoint>) => {
			self.GetEndpointEvent.trigger(res);
		});
	}
	GetProjectLogGroups(request: GetProjectLogGroupsRequest) { 	
        this.webApiService.send('LoggerDictionaryService', 'GetProjectLogGroups' , request);
    }
	GetEndpoints(request: GetEndpointsRequest) { 	
        this.webApiService.send('LoggerDictionaryService', 'GetEndpoints' , request);
    }
	GetEndpoint(request: GetEndpointRequest) { 	
        this.webApiService.send('LoggerDictionaryService', 'GetEndpoint' , request);
    }
	 // async methods
	async getProjectLogGroupsAsync(request: GetProjectLogGroupsRequest): Promise<GetProjectLogGroupsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetProjectLogGroups.bind(this), this.GetProjectLogGroupsEvent)(request);
	}
	async getEndpointsAsync(request: GetEndpointsRequest): Promise<GetEndpointsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpoints.bind(this), this.GetEndpointsEvent)(request);
	}
	async getEndpointAsync(request: GetEndpointRequest): Promise<Endpoint> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpoint.bind(this), this.GetEndpointEvent)(request);
	}
}