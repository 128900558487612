
import { Store } from "vuex";
import Vuex from "vuex";
import FilterModel from "@/views/page/components/filter-model";

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $filter: Store<FilterModel>;
  }
}

const install = (app: any) => {
  const filter = new Vuex.Store({
    state: new FilterModel(),
  });

  app.config.globalProperties.$filter = filter;
};

const filter = {
  install,
};

export default filter;
