import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import GetIndicatorsRequest from '../../../logger-micro-service/services/models/request/GetIndicatorsRequest';
import WsResponseModelT from '../../../schema-builder-server/models/WsResponseModelT';
import GetIndicatorsResponse from '../../../logger-micro-service/services/models/response/GetIndicatorsResponse';

export default class IndicatorsService {
	webApiService: WebApiService;
	GetIndicatorsEvent = new Events<WsResponseModelT<GetIndicatorsResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('IndicatorsService', 'GetIndicators', (res: WsResponseModelT<GetIndicatorsResponse>) => {
			self.GetIndicatorsEvent.trigger(res);
		});
	}
	GetIndicators(request: GetIndicatorsRequest) { 	
        this.webApiService.send('IndicatorsService', 'GetIndicators' , request);
    }
	 // async methods
	async getIndicatorsAsync(request: GetIndicatorsRequest): Promise<GetIndicatorsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetIndicators.bind(this), this.GetIndicatorsEvent)(request);
	}
}