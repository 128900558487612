
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import FilterModel from "@/views/page/components/filter-model";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import ViewFilter from "@/views/components/filter-component/logs-view-filter.vue";

@Options({
  name: "FilterComponent",
  components: {
    LogsViewFilter,
    ViewFilter,
  },
})
export default class FilterComponent extends Vue {
  @Prop({ default: true }) slectSource: boolean;
  @Prop({ default: true }) selectData: boolean;
  @Prop({ default: true }) selectInterval: boolean;
  @Prop({ default: true }) selectLogsFilter: boolean;
  @Prop({ default: true }) selectViewFilter: boolean;
  @Prop({ default: true }) selectButton: boolean;
  @Prop({ default: true }) methods: boolean;
  @Prop({ default: true }) logsType: boolean;
  @Prop({ default: true }) logsLevel: boolean;
  @Prop({ default: true }) url: boolean;
  @Prop({ default: true }) requestId: boolean;
  @Prop({ default: true }) loggerName: boolean;
  @Prop({ default: true }) sessionToken: boolean;
  @Prop({ default: true }) ip: boolean;
  @Prop({ default: true }) userId: boolean;
  @Prop({ default: true }) connectionId: boolean;
  @Prop({ default: true }) shortMessage: boolean;
  @Prop({ default: false }) liveReload: boolean;
  @Prop({ default: false }) projectLogGroupId: boolean;
  @Prop({ default: null }) filter: FilterModel;

  innerFilter: FilterModel = null;
  isLiveReload: boolean = false;

  @Watch("filter", { deep: true })
  onChangeFilter() {
    this.innerFilter = this.filter;
  }

  created() {
    this.innerFilter = this.filter;
  }

  changeSource(value: number) {
    this.innerFilter.ProjectLogGroupId = value;
  }

  changeInterval(value: number) {
    this.innerFilter.interval = value;
  }

  filterData() {
    console.log(this.innerFilter.DateFrom);

    this.$emit("filterData", this.innerFilter);
  }

  filterLogs(value: FilterModel) {
    this.innerFilter.EndpointId = value.EndpointId;
    this.innerFilter.LogType = value.LogType;
    this.innerFilter.LogLevel = value.LogLevel;
    this.innerFilter.HardwareLogsType = value.HardwareLogsType;
    this.innerFilter.Url = value.Url;
    this.innerFilter.SessionToken = value.SessionToken;
    this.innerFilter.Ip = value.Ip;
    this.innerFilter.UserId = value.UserId;
    this.innerFilter.ConnectionId = value.ConnectionId;
    this.innerFilter.RequestId = value.RequestId;
    this.innerFilter.LoggerName = value.LoggerName;
    this.innerFilter.ShortMessage = value.ShortMessage;
    this.innerFilter.ProjectLogGroupId = value.ProjectLogGroupId;
  }

  onLiveReload() {
    this.isLiveReload = !this.isLiveReload;
    this.$emit("onLiveReload", this.isLiveReload);
  }
}
