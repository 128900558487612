
import LocaleProvider from "./localization";

const install = (app: any) => {
  const locale = LocaleProvider;

  app.config.globalProperties.$locale = locale;
};

const locale = {
  install,
};

export default locale;
