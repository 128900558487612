
import { Options, Prop, Vue } from "vue-property-decorator";
import { reactive } from "vue";
@Options({
  name: "LayoutSidebar",
})
export default class LayoutSidebar extends Vue {
  @Prop() hideMenu: boolean;

  minimized: boolean = false;
  min: boolean = false;
  activeArt = false;
  currentRoute = "";
  currentSubMenu = "";

  activeMenu = 0;
  setActivMenu(value: number) {
    this.activeMenu = value;
    return false;
  }

  mounted() {
    this.currentRoute = this.$route.name.toString();
    this.initMenu();

    const startWindowWidth = window.innerWidth;
    this.stateNavPanel(startWindowWidth);

    window.onresize = (event) => {
      const windowWidth = event.target.innerWidth;
      this.stateNavPanel(windowWidth);
    };

    if (this.currentRoute === "content-logs-view") this.activeMenu = 1;

    // console.log(`currentRoute`, this.currentRoute);
  }
  beforeUpdate() {
    // this.currentRoute = this.$route.name.toString();
    // this.initMenu();
  }
  updated() {
    // console.log("updated");
  }
  stateNavPanel(currentWindowWidth: number) {
    if (this.hideMenu) {
      return;
    }
    this.$emit("update:hideMenu", currentWindowWidth < 1000);
  }

  goHome() {
    this.$router.push({ path: "/" });
  }

  // showSubMenu(value: string = null) {
  //   this.activeClassContentLogs();
  //   this.activeClassMetodLogs();
  //   // this.currentRoute = value;
  //   // if (value) {
  //   //   this.$router.push({ name: value });
  //   // }
  //   this.initMenu();
  // }
  initMenu() {
    this.currentSubMenu = "";
    if (
      this.currentRoute === "metod-list" ||
      this.currentRoute === "top-height-methods-load" ||
      this.currentRoute === "logs-statistics" ||
      this.currentRoute === "logs-view-errors"
    ) {
      this.currentSubMenu = "metod-list";
    } else {
      this.currentSubMenu = "";
    }

    if (
      this.currentRoute === "content-logs-view" ||
      this.currentRoute === "content-logs-view-errors"
    ) {
      this.currentSubMenu = "content-logs-view";
    }

    if (
      this.currentRoute === "active-logs-view" ||
      this.currentRoute === "active-logs-view-errors"
    ) {
      this.currentSubMenu = "active-logs-view";
    }
  }
}
