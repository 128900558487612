import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import GetLogRequest from '../../../logger-micro-service/services/models/request/log-getter-service/GetLogRequest';
import GetTextLogsRequest from '../../../logger-micro-service/services/models/request/GetTextLogsRequest';
import GetEndpointLogsRequest from '../../../logger-micro-service/services/models/request/log-getter-service/GetEndpointLogsRequest';
import GetHardwareLogsRequest from '../../../logger-micro-service/services/models/request/log-getter-service/GetHardwareLogsRequest';
import GetActiveLogsRequest from '../../../logger-micro-service/services/models/request/log-getter-service/GetActiveLogsRequest';
import WsResponseModelT from '../../../schema-builder-server/models/WsResponseModelT';
import GetLogsResponse from '../../../logger-micro-service/services/models/response/log-getter-service/GetLogsResponse';
import GetLogDataResponse from '../../../logger-micro-service/services/models/response/log-getter-service/GetLogDataResponse';
import GetTextLogsResponse from '../../../logger-micro-service/services/models/response/GetTextLogsResponse';
import GetTextLogDetailResponse from '../../../logger-micro-service/services/models/response/GetTextLogDetailResponse';
import GetEndpointLogsResponse from '../../../logger-micro-service/services/models/response/log-getter-service/GetEndpointLogsResponse';
import GetActiveEndpointLogsResponse from '../../../logger-micro-service/services/models/response/log-getter-service/GetActiveEndpointLogsResponse';
import GetActiveEndpointLogDetailsResponse from '../../../logger-micro-service/services/models/response/log-getter-service/GetActiveEndpointLogDetailsResponse';
import GetEndpointLogDataResponse from '../../../logger-micro-service/services/models/response/log-getter-service/GetEndpointLogDataResponse';
import GetHardwareLogsResponse from '../../../logger-micro-service/services/models/response/log-getter-service/GetHardwareLogsResponse';
import GetLogDetailsResponse from '../../../logger-micro-service/services/models/response/log-getter-service/GetLogDetailsResponse';
import GetActiveLogsResponse from '../../../logger-micro-service/services/models/response/log-getter-service/GetActiveLogsResponse';
import GetActiveLogDetailsResponse from '../../../logger-micro-service/services/models/response/log-getter-service/GetActiveLogDetailsResponse';
import GetEndpointLogDetailsResponse from '../../../logger-micro-service/services/models/response/log-getter-service/GetEndpointLogDetailsResponse';

export default class LogGetterService {
	webApiService: WebApiService;
	GetLogsEvent = new Events<WsResponseModelT<GetLogsResponse>>();
	GetLogDataEvent = new Events<WsResponseModelT<GetLogDataResponse>>();
	GetTextLogsEvent = new Events<WsResponseModelT<GetTextLogsResponse>>();
	GetTextLogDetailEvent = new Events<WsResponseModelT<GetTextLogDetailResponse>>();
	GetEndpointLogsEvent = new Events<WsResponseModelT<GetEndpointLogsResponse>>();
	GetActiveEndpointLogsEvent = new Events<WsResponseModelT<GetActiveEndpointLogsResponse>>();
	GetActiveEndpointLogDetailsEvent = new Events<WsResponseModelT<GetActiveEndpointLogDetailsResponse>>();
	GetEndpointLogDataEvent = new Events<WsResponseModelT<GetEndpointLogDataResponse>>();
	GetHardwareLogsEvent = new Events<WsResponseModelT<GetHardwareLogsResponse>>();
	GetLogDetailsEvent = new Events<WsResponseModelT<GetLogDetailsResponse>>();
	GetActiveLogsEvent = new Events<WsResponseModelT<GetActiveLogsResponse>>();
	GetActiveLogDetailsEvent = new Events<WsResponseModelT<GetActiveLogDetailsResponse>>();
	GetEndpointLogDetailsEvent = new Events<WsResponseModelT<GetEndpointLogDetailsResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('LogGetterService', 'GetLogs', (res: WsResponseModelT<GetLogsResponse>) => {
			self.GetLogsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetLogData', (res: WsResponseModelT<GetLogDataResponse>) => {
			self.GetLogDataEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetTextLogs', (res: WsResponseModelT<GetTextLogsResponse>) => {
			self.GetTextLogsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetTextLogDetail', (res: WsResponseModelT<GetTextLogDetailResponse>) => {
			self.GetTextLogDetailEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetEndpointLogs', (res: WsResponseModelT<GetEndpointLogsResponse>) => {
			self.GetEndpointLogsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetActiveEndpointLogs', (res: WsResponseModelT<GetActiveEndpointLogsResponse>) => {
			self.GetActiveEndpointLogsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetActiveEndpointLogDetails', (res: WsResponseModelT<GetActiveEndpointLogDetailsResponse>) => {
			self.GetActiveEndpointLogDetailsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetEndpointLogData', (res: WsResponseModelT<GetEndpointLogDataResponse>) => {
			self.GetEndpointLogDataEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetHardwareLogs', (res: WsResponseModelT<GetHardwareLogsResponse>) => {
			self.GetHardwareLogsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetLogDetails', (res: WsResponseModelT<GetLogDetailsResponse>) => {
			self.GetLogDetailsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetActiveLogs', (res: WsResponseModelT<GetActiveLogsResponse>) => {
			self.GetActiveLogsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetActiveLogDetails', (res: WsResponseModelT<GetActiveLogDetailsResponse>) => {
			self.GetActiveLogDetailsEvent.trigger(res);
		});
		webApiService.on('LogGetterService', 'GetEndpointLogDetails', (res: WsResponseModelT<GetEndpointLogDetailsResponse>) => {
			self.GetEndpointLogDetailsEvent.trigger(res);
		});
	}
	GetLogs(request: GetLogRequest) { 	
        this.webApiService.send('LogGetterService', 'GetLogs' , request);
    }
	GetLogData(requestId: string) { 	
        this.webApiService.send('LogGetterService', 'GetLogData' , requestId);
    }
	GetTextLogs(request: GetTextLogsRequest) { 	
        this.webApiService.send('LogGetterService', 'GetTextLogs' , request);
    }
	GetTextLogDetail(requestId: string) { 	
        this.webApiService.send('LogGetterService', 'GetTextLogDetail' , requestId);
    }
	GetEndpointLogs(request: GetEndpointLogsRequest) { 	
        this.webApiService.send('LogGetterService', 'GetEndpointLogs' , request);
    }
	GetActiveEndpointLogs(request: GetEndpointLogsRequest) { 	
        this.webApiService.send('LogGetterService', 'GetActiveEndpointLogs' , request);
    }
	GetActiveEndpointLogDetails(requestId: string) { 	
        this.webApiService.send('LogGetterService', 'GetActiveEndpointLogDetails' , requestId);
    }
	GetEndpointLogData(requestId: string) { 	
        this.webApiService.send('LogGetterService', 'GetEndpointLogData' , requestId);
    }
	GetHardwareLogs(request: GetHardwareLogsRequest) { 	
        this.webApiService.send('LogGetterService', 'GetHardwareLogs' , request);
    }
	GetLogDetails(requestId: string) { 	
        this.webApiService.send('LogGetterService', 'GetLogDetails' , requestId);
    }
	GetActiveLogs(request: GetActiveLogsRequest) { 	
        this.webApiService.send('LogGetterService', 'GetActiveLogs' , request);
    }
	GetActiveLogDetails(requestId: string) { 	
        this.webApiService.send('LogGetterService', 'GetActiveLogDetails' , requestId);
    }
	GetEndpointLogDetails(requestId: string) { 	
        this.webApiService.send('LogGetterService', 'GetEndpointLogDetails' , requestId);
    }
	 // async methods
	async getLogsAsync(request: GetLogRequest): Promise<GetLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogs.bind(this), this.GetLogsEvent)(request);
	}
	async getLogDataAsync(requestId: string): Promise<GetLogDataResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogData.bind(this), this.GetLogDataEvent)(requestId);
	}
	async getTextLogsAsync(request: GetTextLogsRequest): Promise<GetTextLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetTextLogs.bind(this), this.GetTextLogsEvent)(request);
	}
	async getTextLogDetailAsync(requestId: string): Promise<GetTextLogDetailResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetTextLogDetail.bind(this), this.GetTextLogDetailEvent)(requestId);
	}
	async getEndpointLogsAsync(request: GetEndpointLogsRequest): Promise<GetEndpointLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpointLogs.bind(this), this.GetEndpointLogsEvent)(request);
	}
	async getActiveEndpointLogsAsync(request: GetEndpointLogsRequest): Promise<GetActiveEndpointLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetActiveEndpointLogs.bind(this), this.GetActiveEndpointLogsEvent)(request);
	}
	async getActiveEndpointLogDetailsAsync(requestId: string): Promise<GetActiveEndpointLogDetailsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetActiveEndpointLogDetails.bind(this), this.GetActiveEndpointLogDetailsEvent)(requestId);
	}
	async getEndpointLogDataAsync(requestId: string): Promise<GetEndpointLogDataResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpointLogData.bind(this), this.GetEndpointLogDataEvent)(requestId);
	}
	async getHardwareLogsAsync(request: GetHardwareLogsRequest): Promise<GetHardwareLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetHardwareLogs.bind(this), this.GetHardwareLogsEvent)(request);
	}
	async getLogDetailsAsync(requestId: string): Promise<GetLogDetailsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogDetails.bind(this), this.GetLogDetailsEvent)(requestId);
	}
	async getActiveLogsAsync(request: GetActiveLogsRequest): Promise<GetActiveLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetActiveLogs.bind(this), this.GetActiveLogsEvent)(request);
	}
	async getActiveLogDetailsAsync(requestId: string): Promise<GetActiveLogDetailsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetActiveLogDetails.bind(this), this.GetActiveLogDetailsEvent)(requestId);
	}
	async getEndpointLogDetailsAsync(requestId: string): Promise<GetEndpointLogDetailsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpointLogDetails.bind(this), this.GetEndpointLogDetailsEvent)(requestId);
	}
}