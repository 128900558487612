import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tirscript3_dropdown = _resolveComponent("tirscript3-dropdown")!

  return (_openBlock(), _createBlock(_component_tirscript3_dropdown, {
    placeholder: "Интервал",
    items: _ctx.sourceLogsList,
    onChange: _ctx.onChangeInterval,
    modelValue: _ctx.filter.interval,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.interval) = $event))
  }, null, 8, ["items", "onChange", "modelValue"]))
}