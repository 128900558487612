
import {Options, Prop, Vue, Watch} from "vue-property-decorator";

@Options({
  name: "TableHeaderItemComponent"
})
export default class TableHeaderItemComponent extends Vue {
  @Prop({default: null, type: String}) styleCustom: Object = {};
  @Prop({default: null, type: String}) sortName: string = "";
  @Prop({default: "asc", type: String}) defaultSort: string = "";
  @Prop({default: "asc", type: String}) iconAsc: string = "";
  @Prop({default: "desc", type: String}) iconDesk: string = "";
  @Prop({default: "false", type: Boolean}) isNotActiveSort = false;

  sortType: number = 0

  mounted() {
    this.changeSortType()
  }

  changeSortType() {
    if (this.defaultSort == "asc")
      this.sortType = 1;
    else if (this.defaultSort == "desc")
      this.sortType = 0;
    else if (this.defaultSort == null)
      this.sortType = 1
  }

  sortExecute() {
    if (!this.sortName) return;
    if (this.sortType == 1) this.sortType = 0;
    else if (this.sortType == 0) this.sortType = 1;
    this.$emit("onSort", this.sortName, this.sortType);
  }

}
