import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import WsResponseModelT from '../../../schema-builder-server/models/WsResponseModelT';
import GetLogTypesResponse from '../../../logger-micro-service/services/models/response/GetLogTypesResponse';

export default class LogTypeService {
	webApiService: WebApiService;
	GetLogTypesEvent = new Events<WsResponseModelT<GetLogTypesResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('LogTypeService', 'GetLogTypes', (res: WsResponseModelT<GetLogTypesResponse>) => {
			self.GetLogTypesEvent.trigger(res);
		});
	}
	GetLogTypes() { 	
        this.webApiService.send('LogTypeService', 'GetLogTypes' );
    }
	 // async methods
	async getLogTypesAsync(): Promise<GetLogTypesResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogTypes.bind(this), this.GetLogTypesEvent)();
	}
}