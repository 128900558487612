import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "layout"
}
const _hoisted_2 = { class: "container-custom" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layout_sidebar = _resolveComponent("layout-sidebar")!
  const _component_layout_header = _resolveComponent("layout-header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.logined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_layout_sidebar, {
          hideMenu: _ctx.hideMenu,
          "onUpdate:hideMenu": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hideMenu) = $event))
        }, null, 8, ["hideMenu"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_layout_header, {
            hideMenu: _ctx.hideMenu,
            "onUpdate:hideMenu": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hideMenu) = $event))
          }, null, 8, ["hideMenu"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}