import { WebApiService } from '../../plugins/webApiService'
import { Events } from '../../plugins/events'
import EventToAsyncDecorator from '../../plugins/eventToAsyncDecorator'
import RequestLogin from '../../users/i-services/models/request/RequestLogin';
import InviteUserRequest from '../../users/i-services/models/request/InviteUserRequest';
import ActivateUserRequest from '../../users/i-services/models/request/ActivateUserRequest';
import GetUsersRequest from '../../users/i-services/models/request/GetUsersRequest';
import ChangePasswordUserRequest from '../../users/i-services/models/request/ChangePasswordUserRequest';
import ResetPasswordGetTokenRequest from '../../users/i-services/models/request/ResetPasswordGetTokenRequest';
import ResetPasswordRequest from '../../users/i-services/models/request/ResetPasswordRequest';
import WsResponseModelT from '../../schema-builder-server/models/WsResponseModelT';
import SearchUsersResponse from '../../users/i-services/models/response/SearchUsersResponse';
import UserIdentity from '../../logger-micro-service/identity-service/UserIdentity';

export default class UserService {
	webApiService: WebApiService;
	UserLoginEvent = new Events<WsResponseModelT<Boolean>>();
	UserLogoutEvent = new Events<WsResponseModelT<Boolean>>();
	InviteUserEvent = new Events<WsResponseModelT<Boolean>>();
	AcceptInvationEvent = new Events<WsResponseModelT<Boolean>>();
	GetUsersEvent = new Events<WsResponseModelT<SearchUsersResponse>>();
	BlockUserEvent = new Events<WsResponseModelT<Boolean>>();
	ChangePasswordEvent = new Events<WsResponseModelT<Boolean>>();
	ResetPasswordGetTokenEvent = new Events<WsResponseModelT<Boolean>>();
	ResetPasswordEvent = new Events<WsResponseModelT<Boolean>>();
	GetCurrentUserEvent = new Events<WsResponseModelT<UserIdentity>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('UserService', 'UserLogin', (res: WsResponseModelT<Boolean>) => {
			self.UserLoginEvent.trigger(res);
		});
		webApiService.on('UserService', 'UserLogout', (res: WsResponseModelT<Boolean>) => {
			self.UserLogoutEvent.trigger(res);
		});
		webApiService.on('UserService', 'InviteUser', (res: WsResponseModelT<Boolean>) => {
			self.InviteUserEvent.trigger(res);
		});
		webApiService.on('UserService', 'AcceptInvation', (res: WsResponseModelT<Boolean>) => {
			self.AcceptInvationEvent.trigger(res);
		});
		webApiService.on('UserService', 'GetUsers', (res: WsResponseModelT<SearchUsersResponse>) => {
			self.GetUsersEvent.trigger(res);
		});
		webApiService.on('UserService', 'BlockUser', (res: WsResponseModelT<Boolean>) => {
			self.BlockUserEvent.trigger(res);
		});
		webApiService.on('UserService', 'ChangePassword', (res: WsResponseModelT<Boolean>) => {
			self.ChangePasswordEvent.trigger(res);
		});
		webApiService.on('UserService', 'ResetPasswordGetToken', (res: WsResponseModelT<Boolean>) => {
			self.ResetPasswordGetTokenEvent.trigger(res);
		});
		webApiService.on('UserService', 'ResetPassword', (res: WsResponseModelT<Boolean>) => {
			self.ResetPasswordEvent.trigger(res);
		});
		webApiService.on('UserService', 'GetCurrentUser', (res: WsResponseModelT<UserIdentity>) => {
			self.GetCurrentUserEvent.trigger(res);
		});
	}
	UserLogin(request: RequestLogin) { 	
        this.webApiService.send('UserService', 'UserLogin' , request);
    }
	UserLogout() { 	
        this.webApiService.send('UserService', 'UserLogout' );
    }
	InviteUser(request: InviteUserRequest) { 	
        this.webApiService.send('UserService', 'InviteUser' , request);
    }
	AcceptInvation(request: ActivateUserRequest) { 	
        this.webApiService.send('UserService', 'AcceptInvation' , request);
    }
	GetUsers(request: GetUsersRequest) { 	
        this.webApiService.send('UserService', 'GetUsers' , request);
    }
	BlockUser(id: number) { 	
        this.webApiService.send('UserService', 'BlockUser' , id);
    }
	ChangePassword(request: ChangePasswordUserRequest) { 	
        this.webApiService.send('UserService', 'ChangePassword' , request);
    }
	ResetPasswordGetToken(request: ResetPasswordGetTokenRequest) { 	
        this.webApiService.send('UserService', 'ResetPasswordGetToken' , request);
    }
	ResetPassword(request: ResetPasswordRequest) { 	
        this.webApiService.send('UserService', 'ResetPassword' , request);
    }
	GetCurrentUser() { 	
        this.webApiService.send('UserService', 'GetCurrentUser' );
    }
	 // async methods
	async userLoginAsync(request: RequestLogin): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.UserLogin.bind(this), this.UserLoginEvent)(request);
	}
	async userLogoutAsync(): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.UserLogout.bind(this), this.UserLogoutEvent)();
	}
	async inviteUserAsync(request: InviteUserRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.InviteUser.bind(this), this.InviteUserEvent)(request);
	}
	async acceptInvationAsync(request: ActivateUserRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.AcceptInvation.bind(this), this.AcceptInvationEvent)(request);
	}
	async getUsersAsync(request: GetUsersRequest): Promise<SearchUsersResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetUsers.bind(this), this.GetUsersEvent)(request);
	}
	async blockUserAsync(id: number): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.BlockUser.bind(this), this.BlockUserEvent)(id);
	}
	async changePasswordAsync(request: ChangePasswordUserRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.ChangePassword.bind(this), this.ChangePasswordEvent)(request);
	}
	async resetPasswordGetTokenAsync(request: ResetPasswordGetTokenRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.ResetPasswordGetToken.bind(this), this.ResetPasswordGetTokenEvent)(request);
	}
	async resetPasswordAsync(request: ResetPasswordRequest): Promise<Boolean> {
		return EventToAsyncDecorator.getInstance().bind(this.ResetPassword.bind(this), this.ResetPasswordEvent)(request);
	}
	async getCurrentUserAsync(): Promise<UserIdentity> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCurrentUser.bind(this), this.GetCurrentUserEvent)();
	}
}