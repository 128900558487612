import {
  createRouter,
  createWebHistory,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  RouteRecordRaw,
} from "vue-router";
import Layout from "@layouts/layout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/authorization",
    name: "authorization",
    component: () => import("@/views/page/login/authorization.vue"),
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: () => import("@/views/page/login/password-recovery.vue"),
  },
  {
    path: "/",
    name: "layout",
    component: Layout,
    children: [
      {
        path: "/",
        redirect: "content-logs-view",
      },
      {
        path: "/",
        name: "current-indicator",
        component: () =>
          import("@/views/page/indicators/current-indicator-page.vue"),
      },
      {
        path: "/cpu-load",
        name: "cpu-load",
        component: () => import("@/views/page/cpu-load/cpu-load-page.vue"),
      },
      // Логи методов
      {
        path: "/metod-list",
        name: "metod-list",
        component: () =>
          import("@/views/page/methods-of-logs/metod-list/metod-list.vue"),
      },

      // Среднее время отклика
      {
        name: "average-response-time",
        path: "/metod-list/average-response-time/:Id/:Name/:DateFrom/:DateTo",
        component: () =>
          import(
            "@/views/page/methods-of-logs/metod-list/average-response-time.vue"
          ),
      },
      // Статистика по пользователям
      {
        name: "users-statistic",
        path: "/metod-list/users-statistic/:Id/:Name/:DateFrom/:DateTo",
        component: () =>
          import("@/views/page/methods-of-logs/metod-list/users-statistic.vue"),
      },
      // Среднее время отклика методов
      {
        path: "/average-response-time-of-methods",
        name: "average-response-time-of-methods",
        component: () =>
          import(
            "@/views/page/methods-of-logs/average-response-time-of-methods.vue"
          ),
      },
      // Колличество открытых и закрытых сессий
      {
        path: "/number-of-open-and-closed-sessions",
        name: "number-of-open-and-closed-sessions",
        component: () =>
          import(
            "@/views/page/methods-of-logs/number-of-open-and-closed-sessions.vue"
          ),
      },
      // Колличество активных пользователей
      {
        path: "/projects",
        name: "projects",
        component: () =>
          import("@/views/page/projects/projects-page.vue"),
      },
      // Список проектов
      {
        path: "/number-active-users",
        name: "number-active-users",
        component: () =>
          import("@/views/page/methods-of-logs/number-active-users.vue"),
      },

      {
        path: "/logs-view",
        name: "logs-view",
        component: () =>
          import("@/views/page/methods-of-logs/logs-view-page.vue"),
      },
      {
        path: "/active-logs-view",
        name: "active-logs-view",
        component: () =>
          import("@/views/page/methods-of-logs/active-logs-view-page.vue"),
      },
      {
        path: "/logs-view-detail/:id/:isActive",
        name: "logs-view-detail",
        component: () =>
          import("@/views/page/methods-of-logs/logs-view-detail-page.vue"),
      },
      {
        path: "/top-height-methods-load",
        name: "top-height-methods-load",
        component: () =>
          import(
            "@/views/page/methods-of-logs/top-height-methods-load-page.vue"
          ),
      },
      {
        path: "/logs-statistics",
        name: "logs-statistics",
        component: () =>
          import("@/views/page/methods-of-logs/logs-statistics-page.vue"),
      },
      {
        path: "/logs-view-errors",
        name: "logs-view-errors",
        component: () =>
          import("@/views/page/methods-of-logs/logs-view-errors-page.vue"),
      },

      // Логи контента
      {
        path: "/content-logs-view",
        name: "content-logs-view",
        component: () =>
          import("@views/page/content-logs/content-logs-view-page.vue"),
      },
      {
        path: "/content-logs-view-active",
        name: "content-logs-view-active",
        component: () =>
          import("@views/page/content-logs/content-logs-view-active-page.vue"),
      },
      {
        path: "/content-logs-view-errors",
        name: "content-logs-view-errors",
        component: () =>
          import("@views/page/content-logs/content-logs-view-errors-page.vue"),
      },
      {
        path: "/content-logs-detail/:id/:isActive",
        name: "content-logs-detail",
        component: () =>
          import("@views/page/content-logs/content-logs-detail-page.vue"),
      },
      // Список пользователей
      {
        path: "/user-list",
        name: "user-list",
        component: () => import("@/views/page/user-list.vue"),
      },
      // Текстовые логи
      {
        path: "/text-logs-view",
        name: "text-logs-view",
        component: () => import("@/views/page/text-logs/text-logs-page.vue"),
      },
      {
        path: "/text-logs-detail/:id",
        name: "text-logs-detail",
        component: () =>
          import("@views/page/text-logs/text-logs-detail-page.vue"),
      },
      // Http логи
      {
        path: "/http-logs-view",
        name: "http-logs-view",
        component: () => import("@/views/page/http-logs/http-logs-page.vue"),
      },
      {
        path: "/http-logs-detail/:id",
        name: "http-logs-detail",
        component: () =>
          import("@views/page/http-logs/http-logs-detail-page.vue"),
      },


      {
        path: "/http-top-height-methods-load/",
        name: "http-top-height-methods-load",
        component: () => import("@/views/page/http-logs/http-top-height-methods-load-page.vue"),
      },
      {
        path: "/http-logs-detail/:id/:top",
        name: "http-top-logs-detail",
        component: () =>
          import("@views/page/http-logs/http-logs-detail-page.vue"),
      },


      {
        path: "/http-active-logs-view",
        name: "http-active-logs-view",
        component: () => import("@/views/page/http-logs/active/http-active-logs-page.vue"),
      },
      // {
      //   path: "/http-active-logs-detail/:id",
      //   name: "http-active-logs-detail",
      //   component: () =>
      //     import("@views/page/http-logs/active/http-active-logs-detail-page.vue"),
      // },

      // ---------------------------------------------------ws логи------------------------------------------------------
      {
        path: "/ws-logs-view",
        name: "ws-logs-view",
        component: () => import("@/views/page/ws-logs/ws-logs-page.vue"),
      },
      {
        path: "/ws-logs-detail/:id",
        name: "ws-logs-detail",
        component: () =>
          import("@views/page/ws-logs/ws-logs-detail-page.vue"),
      },

      {
        path: "/ws-active-logs-view",
        name: "ws-active-logs-view",
        component: () => import("@/views/page/ws-logs/active/ws-active-logs-page.vue"),
      },
      // {
      //   path: "/ws-active-logs-detail/:id",
      //   name: "ws-active-logs-detail",
      //   component: () =>
      //     import("@views/page/ws-logs/active/ws-active-logs-detail-page.vue"),
      // },



      // Администрирование
      {
        path: "/administration",
        name: "administration",
        component: () => import("@/views/page/administration.vue"),
      },
      // Мой профиль
      {
        path: "/my-profile",
        name: "my-profile",
        component: () => import("@/views/page/my-profile.vue"),
      },
      /**
       * {
        "DateEnd": "2023-12-19T08:52:47.677067",
        "DateStart": "2023-12-19T08:52:47.677067",
        "ConnectionId": "abc4f8f0-13be-49c0-804c-c2a61bdc3900",
        "ProjectLogGroupId": 59,
        "Level": 2,
        "Message": "Manager microservice accept client[67a4af26-8b74-42bf-ab1a-91b119f3b258] connection.",
        "ServerName": "YandexKuber",
        "ProjectName": "WebProxy"
      }
       * 
       */
      // active-methods-logs
      // {
      //   path: "/active-logs-view",
      //   name: "active-logs-view",
      //   component: () => import("@views/page/active-logs/active-logs-view-page.vue"),
      // },
      // {
      //   path: "/active-logs-view-errors",
      //   name: "active-logs-view-errors",
      //   component: () => import("@views/page/active-logs/active-logs-view-errors-page.vue"),
      // },

      // hardware logs
      {
        path: "/hardware-logs",
        name: "hardware-logs",
        component: () =>
          import("@views/page/hardware-logs/hardware-logs-page.vue"),
      },
    ],
  },
];
/* webpackChunkName: "login" */

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
