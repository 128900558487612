
import api from "@/api";
import Cookies from "js-cookie";
import { Options } from "vue-class-component";
import { Vue } from "vue-property-decorator";

export default class LayoutHeader extends Vue {
  hideMenu: boolean;
  userName = "";
  burger = false;
  // Получаем имя текущего пользователя
  async created() {
    this.$api.UserService.GetCurrentUserEvent.once((res) => {
      if (res.IsSuccess) {
        this.userName = res.Value.Email;
      }
    });
    this.$api.UserService.GetCurrentUser();
  }

  changeProfile() {
    this.$router.push({ name: "profile" });
    this.$emit("changeProfile");
  }
  // Выход из аккаунта
  onLogout() {
    this.$api.UserService.UserLogoutEvent.once((res) => {
      if (res.IsSuccess) {
        Cookies.remove("Auth-Token");
        window.open("authorization", "_self");
      }
    });
    this.$api.UserService.UserLogout();
  }

  onClickBurger() {
    this.hideMenu = !this.hideMenu;
    this.$emit("update:hideMenu", this.hideMenu);
  }
}
