
import { Options, Vue } from "vue-class-component";
import { Watch, Prop } from "vue-property-decorator";
import ListViewItem from "tirscript3-list-view/src/ListViewItem";
import DefaultModel from "tirscript3-component-models/src/defaultModel";
import FilterModel from "@views/page/components/filter-model";
import GetEndpointsByNameResponse from "@/dataSource/api/logger-micro-service/services/models/response/endpoint-service/GetEndpointsByNameResponse";
import EnumLogType from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogType";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";
@Options<LogsViewFilter>({
  name: "logs-wiew-filter",
  components: {},
  props: {
    logsType: { default: true, type: Boolean },
    logsLevel: { default: true, type: Boolean },
    hardwareLogsType: { default: false, type: Boolean },
    requestId: { default: true, type: Boolean },
    url: { default: true, type: Boolean },
    addPanel: { default: true, type: Boolean },
    methods: { default: false, type: Boolean },
    connectionId: { default: true, type: Boolean },
    projectLogGroupId: { default: false, type: Boolean },
    userId: { default: true, type: Boolean },
    sessionToken: { default: true, type: Boolean },
    ip: { default: true, type: Boolean },
    loggerName: { default: true, type: Boolean },
    shortMessage: { default: true, type: Boolean },
  },
})
export default class LogsViewFilter extends Vue {
  @Prop({ default: null, type: Object }) defaultFilter: FilterModel;

  filter: FilterModel = new FilterModel();
  readonly logsType: boolean;
  readonly "logs-level": boolean;
  readonly "hardwareLogsType": boolean;
  readonly "url": boolean;
  readonly "requestId": boolean;
  readonly "methods": boolean;
  readonly "addPanel": boolean;
  readonly "sessionToken": boolean;
  readonly "ip": boolean;
  readonly "userId": boolean;
  readonly "connectionId": boolean;
  readonly "projectLogGroupId": boolean;
  readonly "loggerName": boolean;
  readonly "shortMessage": boolean;
  controllerMethods: ListViewItem<DefaultModel>[] = [];
  logsLevels: ListViewItem<DefaultModel>[] = [];
  logsTypes: ListViewItem<DefaultModel>[] = [];
  hardwareLogsTypes: ListViewItem<DefaultModel>[] = [];
  searchEndpoint = "";

  @Watch("defaultFilter", { deep: true })
  onChangeFilter() {
    if (this.defaultFilter) {
      this.filter = new FilterModel(this.defaultFilter);
      this.getlogsLevels();
      this.getlogsTypes();
      this.getControllerMethods();
    }
  }

  created() {
    if (this.defaultFilter) {
      this.filter = new FilterModel(this.defaultFilter);
    }
  }

  @Watch("filter", { deep: true })
  onFilter(newFilter: FilterModel) {
    this.filter = newFilter;
    this.$emit("onFilter", this.filter);
  }
  mounted() {
    this.getlogsLevels();
    this.getlogsTypes();
    this.getControllerMethods();
    this.gethardwareLogsTypes();
  }
  onChangeLogsType(items: ListViewItem<DefaultModel>[]) {
    let id = (items.filter((el) => el.Selected === true)[0].Value as DefaultModel).id;
    if (id == -1) id = null;
    this.filter.LogType = id;
  }
  onChangeHardwareLogsType(items: ListViewItem<DefaultModel>[]) {
    let id = (items.filter((el) => el.Selected === true)[0].Value as DefaultModel).id;
    if (id == -1) id = null;
    this.filter.HardwareLogsType = id;
  }
  onChangeLogsLevel(items: ListViewItem<DefaultModel>[]) {
    let id = (items.filter((el) => el.Selected === true)[0].Value as DefaultModel).id;

    if (id == -1) id = null;
    this.filter.LogLevel = id;
  }
  onChangeControllerMethods(items: ListViewItem<DefaultModel>[]) {
    let id = (items.filter((el) => el.Selected === true)[0].Value as DefaultModel).id;
    if (id == -1) id = null;
    this.filter.EndpointId = id;
  }

  getlogsLevels() {
    let enumLogLavel = EnumLogLevel;
    this.logsLevels = [
      new ListViewItem(
        new DefaultModel(null, "All logs level"),
        !this.filter.LogLevel && this.filter.LogLevel != 0,
        false
      ),
    ];
    for (let index = 0; index < 100; index++) {
      let name = this.$filters.GetEnumNameByValue(enumLogLavel, index.toString());

      if (!name) break;
      this.logsLevels.push(
        new ListViewItem(
          new DefaultModel(index, name),
          this.filter.LogLevel == index,
          false
        )
      );
    }

    this.filter.LogLevel = (this.logsLevels.find((level) => level.Selected)
      .Value as DefaultModel).id;
  }
  onChangeAutocomlite(value: string) {
    this.searchEndpoint = value;
    this.getControllerMethods();
  }
  async getControllerMethods() {
    if (!this.methods) {
      return;
    }
    this.controllerMethods = [];

    this.controllerMethods.push(
      new ListViewItem(
        new DefaultModel(null, "Любой контроллер/метод"),
        !this.defaultFilter.EndpointId,
        false
      )
    );
    let res: GetEndpointsByNameResponse = null;
    try {
      res = await this.$api.EndpointService.getEndpointsAsync({
        Page: { Skip: 0, Take: 20 },
        Search: this.searchEndpoint,
      });
    } catch (error) {
      return;
    }

    res.Items.forEach((el) => {
      this.controllerMethods.push(
        new ListViewItem(
          new DefaultModel(el.Id, `${el.Class}/${el.Method}`),
          this.defaultFilter.EndpointId == el.Id,
          false
        )
      );
    });
    this.filter.controllerMethod = (this.controllerMethods.find((el) => el.Selected)
      .Value as DefaultModel).id;
  }
  getlogsTypes() {
    this.logsTypes = [
      new ListViewItem(
        new DefaultModel(null, "All logs types"),
        !this.filter.LogType && this.filter.LogType != 0,
        false
      ),
    ];

    Object.keys(EnumLogType)
      .slice(0, Object.keys(EnumLogType).length / 2)
      .forEach((element) => {
        this.logsTypes.push(
          new ListViewItem(
            new DefaultModel(
              Number(element),
              this.$filters.LogType(Number(element)).toString()
            ),
            this.filter.LogType == Number(element),
            false
          )
        );
      });

    this.filter.LogType = (this.logsTypes.find((el) => el.Selected)
      .Value as DefaultModel).id;
  }
  gethardwareLogsTypes() {
    this.hardwareLogsTypes = [
      new ListViewItem(new DefaultModel(-1, "Все типы мониторинга"), true, false),
    ];
    for (let index = 0; index < 2; index++) {
      this.hardwareLogsTypes.push(
        new ListViewItem(
          new DefaultModel(index, this.$filters.HardwareLogType(index).toString()),
          false,
          false
        )
      );
    }
    this.filter.HardwareLogsType = (this.hardwareLogsTypes[0].Value as DefaultModel).id;
  }
  clearFilter() {
    this.filter.Url = "";
    this.filter.LogType = null;
    this.filter.LogLevel = null;
    this.filter.HardwareLogsType = null;
    this.filter.Url = "";
    this.filter.SessionToken = "";
    this.filter.Ip = "";
    this.filter.UserId = "";
    this.filter.ConnectionId = "";
    this.filter.RequestId = "";
    this.filter.ProjectLogGroupId = null;
    this.filter.LoggerName = "";
    this.filter.ShortMessage = "";

    this.$emit("clear");
  }
}
