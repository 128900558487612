
import { Options, Vue } from "vue-class-component";
import LayoutHeader from "@layouts/layout-header.vue";
import LayoutSidebar from "@layouts/layout-sidebar.vue";
import UiNotification from "@/plugins/notification/ui-notification.vue";
import { reactive } from "vue";
import { useApiStore } from "../../shared/stores/api.store";
// import { storeToRefs } from "pinia";
@Options({
  components: {
    LayoutHeader,
    LayoutSidebar,
    UiNotification,
  },

  provide() {
    return {
      user: this.user,
      settings: this.settings,
    };
  },
})
export default class Layout extends Vue {
  MyDependency = {
    foo: "bar",
  };
  settings = reactive({ theme: "light" });
  user = "John Doe";
  logined = true;
  hideMenu = false;
  // api;
  mounted() {
    // let storApi = storeToRefs(useApiStore());
    // console.log(`this.api`, storApi.v.value);
    // storApi.v.value = "2";
  }

  onClickBurger(value: boolean) {
    this.hideMenu = value;
  }
}
