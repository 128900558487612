import { WebApiService } from '../../plugins/webApiService'
import { Events } from '../../plugins/events'
import EventToAsyncDecorator from '../../plugins/eventToAsyncDecorator'
import StatRequest from '../../analytic-endpoints/i-services/models/request/StatRequest';
import EndpointsRequest from '../../analytic-endpoints/i-services/models/request/EndpointsRequest';
import EndpointTimeStatisticRequest from '../../analytic-endpoints/i-services/models/request/EndpointTimeStatisticRequest';
import EndpointUsersStatisticRequest from '../../analytic-endpoints/i-services/models/request/EndpointUsersStatisticRequest';
import EndpointsTimeStatisticAllRequest from '../../analytic-endpoints/i-services/models/request/EndpointsTimeStatisticAllRequest';
import SessionStatisticRequest from '../../analytic-endpoints/i-services/models/request/SessionStatisticRequest';
import WsResponseModelT from '../../schema-builder-server/models/WsResponseModelT';
import StatResponseGrouped from '../../analytic-endpoints/i-services/models/response/StatResponseGrouped';
import StatResponseGroupedNoAnswered from '../../analytic-endpoints/i-services/models/response/StatResponseGroupedNoAnswered';
import EndpointsResponse from '../../analytic-endpoints/i-services/models/response/EndpointsResponse';
import EndpointTimeStatisticResponse from '../../analytic-endpoints/i-services/models/response/EndpointTimeStatisticResponse';
import EndpointUsersStatisticResponse from '../../analytic-endpoints/i-services/models/response/EndpointUsersStatisticResponse';
import EndpointsTimeStatisticAllResponse from '../../analytic-endpoints/i-services/models/response/EndpointsTimeStatisticAllResponse';
import SessionStatisticResponse from '../../analytic-endpoints/i-services/models/response/SessionStatisticResponse';

export default class AnalyticEndpointsService {
	webApiService: WebApiService;
	GetStatisticEvent = new Events<WsResponseModelT<Array<StatResponseGrouped>>>();
	GetStatisticNoAnsweredEvent = new Events<WsResponseModelT<Array<StatResponseGroupedNoAnswered>>>();
	GetEndpointsEvent = new Events<WsResponseModelT<EndpointsResponse>>();
	GetEndpointTimeStatisticEvent = new Events<WsResponseModelT<Array<EndpointTimeStatisticResponse>>>();
	GetEndpointUsersStatisticEvent = new Events<WsResponseModelT<Array<EndpointUsersStatisticResponse>>>();
	GetAllEndpointsTimeStatisticEvent = new Events<WsResponseModelT<Array<EndpointsTimeStatisticAllResponse>>>();
	GetSessionStatisticEvent = new Events<WsResponseModelT<Array<SessionStatisticResponse>>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('AnalyticEndpointsService', 'GetStatistic', (res: WsResponseModelT<Array<StatResponseGrouped>>) => {
			self.GetStatisticEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetStatisticNoAnswered', (res: WsResponseModelT<Array<StatResponseGroupedNoAnswered>>) => {
			self.GetStatisticNoAnsweredEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetEndpoints', (res: WsResponseModelT<EndpointsResponse>) => {
			self.GetEndpointsEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetEndpointTimeStatistic', (res: WsResponseModelT<Array<EndpointTimeStatisticResponse>>) => {
			self.GetEndpointTimeStatisticEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetEndpointUsersStatistic', (res: WsResponseModelT<Array<EndpointUsersStatisticResponse>>) => {
			self.GetEndpointUsersStatisticEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetAllEndpointsTimeStatistic', (res: WsResponseModelT<Array<EndpointsTimeStatisticAllResponse>>) => {
			self.GetAllEndpointsTimeStatisticEvent.trigger(res);
		});
		webApiService.on('AnalyticEndpointsService', 'GetSessionStatistic', (res: WsResponseModelT<Array<SessionStatisticResponse>>) => {
			self.GetSessionStatisticEvent.trigger(res);
		});
	}
	GetStatistic(request: StatRequest) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetStatistic' , request);
    }
	GetStatisticNoAnswered(request: StatRequest) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetStatisticNoAnswered' , request);
    }
	GetEndpoints(request: EndpointsRequest) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetEndpoints' , request);
    }
	GetEndpointTimeStatistic(request: EndpointTimeStatisticRequest) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetEndpointTimeStatistic' , request);
    }
	GetEndpointUsersStatistic(request: EndpointUsersStatisticRequest) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetEndpointUsersStatistic' , request);
    }
	GetAllEndpointsTimeStatistic(request: EndpointsTimeStatisticAllRequest) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetAllEndpointsTimeStatistic' , request);
    }
	GetSessionStatistic(request: SessionStatisticRequest) { 	
        this.webApiService.send('AnalyticEndpointsService', 'GetSessionStatistic' , request);
    }
	FindPeriod() { 	
        this.webApiService.send('AnalyticEndpointsService', 'FindPeriod' );
    }
	 // async methods
	async getStatisticAsync(request: StatRequest): Promise<Array<StatResponseGrouped>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetStatistic.bind(this), this.GetStatisticEvent)(request);
	}
	async getStatisticNoAnsweredAsync(request: StatRequest): Promise<Array<StatResponseGroupedNoAnswered>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetStatisticNoAnswered.bind(this), this.GetStatisticNoAnsweredEvent)(request);
	}
	async getEndpointsAsync(request: EndpointsRequest): Promise<EndpointsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpoints.bind(this), this.GetEndpointsEvent)(request);
	}
	async getEndpointTimeStatisticAsync(request: EndpointTimeStatisticRequest): Promise<Array<EndpointTimeStatisticResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpointTimeStatistic.bind(this), this.GetEndpointTimeStatisticEvent)(request);
	}
	async getEndpointUsersStatisticAsync(request: EndpointUsersStatisticRequest): Promise<Array<EndpointUsersStatisticResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetEndpointUsersStatistic.bind(this), this.GetEndpointUsersStatisticEvent)(request);
	}
	async getAllEndpointsTimeStatisticAsync(request: EndpointsTimeStatisticAllRequest): Promise<Array<EndpointsTimeStatisticAllResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetAllEndpointsTimeStatistic.bind(this), this.GetAllEndpointsTimeStatisticEvent)(request);
	}
	async getSessionStatisticAsync(request: SessionStatisticRequest): Promise<Array<SessionStatisticResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetSessionStatistic.bind(this), this.GetSessionStatisticEvent)(request);
	}
}