import Cookies from 'js-cookie';
//const Cookies = require('js-cookie');
// import ImportCookies from "js-cookie";
// let Cookies: any;
// try {
//   let req = require("js-cookie");
//   Cookies = req;
// } catch {
//   Cookies = ImportCookies;
// }

import TokenSaveType from "./tokenSaveType";

enum EnumErrorCodes {
  SessionEnded = 5,
}

export class WebApiService {
  websocket: WebSocket;
  subscribers = new Array<SocketSubscribersModel>();
  sendOrder = new Array<string>();
  url: string;
  public cookieName = "Auth-Token";
  tokenSaveType: TokenSaveType = TokenSaveType.Cookies;
  /**
   *включить реконнекты при потере соединения
   *
   * @type {boolean}
   * @memberof WebApiService
   */
  reconnectEnabled: boolean = true;
  /**
   *Время простоя перед реконнектом после потери соединения
   *
   * @type {number}
   * @memberof WebApiService
   */
  reconnectTimeout: number = 10 * 1000;
  /**
   *флаг обозначающий наличие подключения к вебсокету
   *
   * @type {boolean}
   * @memberof WebApiService
   */
  connected: boolean = false;
  /**
   * Флаг обозначающий, предназначено ли это апи для использования на микрофронтах
   *
   * @type {boolean}
   * @memberof WebApiService
   */
  microFront: boolean = false;
  /**
   * включить увеличение интервала ожидания реконнекта при неуспешных коннектах
   */
  reconnectIncreaseIntervalEnabled: boolean = false;
  /**
   * Количество попыток реконекта, сколько уже сделано
   */
  reconnectAttempts: number = 0;

  /**
   * Функция для фильтрации запросов отправляемых после реконнекта
   */
  filterSendOrderFunc = (sendOrder: string[]): string[] => sendOrder
  /**
     *
     * @param url url для подключения к вебсокету. должен начинаться с ws:// или с wss://
     */
  constructor(private baseUrl: string, cookieName: string = "Auth-Token") {
    this.cookieName = cookieName;
  }
  public init() {
    this.initUrl();
    this.connect();
  }
  private initUrl() {
    this.url = this.addTokenToUrl(this.baseUrl);
  }
  /**
   * Подключиться к вебсокету
   */
  private connect() {
    this.dispose();
    if (this.microFront) {
      let key = this.baseUrl;
      if ((window as any).__proto__[key]) {
        this.websocket = (window as any).__proto__[key] as WebSocket;
      } else {
        this.websocket = new WebSocket(this.url);
        (window as any).__proto__[key] = this.websocket;
      }
    } else {
      this.websocket = new WebSocket(this.url);
    }
    this.websocket.addEventListener("message", this.onMessage.bind(this));
    this.websocket.addEventListener("open", this.onOpen.bind(this));
    this.websocket.addEventListener("error", this.onError.bind(this));
    this.websocket.addEventListener("close", this.onClose.bind(this));
    this.connected = true;
  }
  private clearReconnect() {
    // удалить токен
    this.clearToken();
    // пересоздать урл с новым токеном
    this.initUrl();
    // переподключить
    this.connect();
  }
  /**
   * уничтожить объект вебсокет
   */
  private dispose() {
    if (!this.websocket) return;
    this.websocket.removeEventListener("message", this.onMessage.bind(this));
    this.websocket.removeEventListener("open", this.onOpen.bind(this));
    this.websocket.removeEventListener("error", this.onError.bind(this));
    this.websocket.removeEventListener("close", this.onClose.bind(this));
    //this.websocket = null;
  }
  /**
   * Удаляем токен из куки
   */
  private clearToken() {
    if (this.tokenSaveType == TokenSaveType.Cookies) {
      Cookies.remove(this.cookieName);
    } else {
      localStorage.removeItem(this.cookieName);
    }
  }
  private newGuid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  private getCookieToken(): string {
    var token = Cookies.get(this.cookieName);
    if (!token) token = this.newGuid();
    // 30 days
    Cookies.set(this.cookieName, token, {
      expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
    });
    return token;
  }

  private getLocalStorageToken(): string {
    var token = localStorage.getItem(this.cookieName);
    if (!token) token = this.newGuid();
    localStorage.setItem(this.cookieName, token);
    return token;
  }

  private getToken(): string {
    var token = "";
    switch (this.tokenSaveType) {
      case TokenSaveType.Cookies:
        token = this.getCookieToken();
        break;
      case TokenSaveType.LocalStore:
        token = this.getLocalStorageToken();
        break;
    }
    return token;
  }
  private addTokenToUrl(url: string): string {
    var token = this.getToken();
    if (url.indexOf("?") >= 0) return url + "&token=" + token;
    return url + "?token=" + token;
  }
  onMessage(msg: MessageEvent) {
    const json = JSON.parse(msg.data);
    var controller = json.Class;
    var method = json.Method;
    var data = json.Value;
    // if(!json.IsSuccess)
    //   console.error('error response: ',json)
    var callers = this.subscribers.filter((x) => {
      return x.controller == controller && x.method == method;
    });
    callers.forEach((c) => c.callback(json));
    var errorCode = (json || {}).ErrorCode || 0;
    if (errorCode == EnumErrorCodes.SessionEnded) {
      this.clearReconnect();
    }
  }
  onError(e: Event) {
    console.error("Websocket unexpected ERROR", e);
    this.connected = false;
  }
  onClose(e: CloseEvent) {
    console.error("Websocket CLOSED", e);
    this.connected = false;
    var self = this;
    if (this.reconnectEnabled)
      setTimeout(function () {
        self.reconnectAttempts += 1;
        self.connect();
      }, self.getReconnectTimeout());
  }
  onOpen() {
    this.reconnectAttempts = 0;
    if (this.sendOrder && this.sendOrder.length) {
      const filteredSendOrder = this.filterSendOrderFunc(this.sendOrder)
      if (filteredSendOrder && filteredSendOrder.length) {
        filteredSendOrder.forEach((so) => this.websocket.send(so));
      }
    }

    this.sendOrder = new Array<string>();
  }
  on(controller: string, method: string, callback: Function) {
    this.subscribers.push({
      controller: controller,
      method: method,
      callback: callback,
    });
  }
  send(controller: string, method: string, data?: any) {
    var value = data;
    if (typeof data !== "string")
      value = JSON.stringify(data === undefined || data === null ? "" : data);

    var sendData = JSON.stringify({
      controller: controller,
      method: method,
      value: value,
    });
    if (this.websocket.readyState != 1) {
      // open
      this.sendOrder.push(sendData);
      return;
    }
    this.websocket.send(sendData);
  }
  /**
   * получить дополнительный интервал к рекконекту
   * @param interval 
   * @returns 
   */
  private getExtraReconnectInterval(interval: number) {
    // если интервал уже 1 и более минут
    if (interval >= 60) {
      return Math.floor(Math.random() * 30)
    }
    return Math.floor(Math.random() * 15)
  }
  /**
   * получить время простоя перед реконнектом
   * @returns 
   */
  private getReconnectTimeout(): number {
    if(!this.reconnectIncreaseIntervalEnabled)
      return this.reconnectTimeout;

    if(this.reconnectAttempts == 0)
      return this.reconnectTimeout;

    
    let interval = this.reconnectTimeout / 1000;    
    
    // увеличиваем интервал на 20 сек, до достижения 5 мин
    if (interval < 300) {
      interval = this.reconnectAttempts * 20;
    }
    else {
      interval = 300;
    }
    // добавляем дополнительный интервал (рандом 0-30 сек)
    interval += this.getExtraReconnectInterval(interval);
    
    
    return interval * 1000;
  }
}
interface SocketSubscribersModel {
  controller: string;
  method: string;
  callback: Function;
}
