import HttpMethodEnum from "@/dataSource/api/tirscript/logger-micro-service/enums/HttpMethodEnum";
export default function getEnumNameByValue(enumName: any, value: string): string | undefined {
  // Получаем массив записей [ключ, значение] из enum
  const enumEntries = Object.entries(enumName);

  // Ищем запись с заданным значением
  const matchingEntry = enumEntries.find((entry) => entry[1] === value || entry[1].toString() === value);

  // Если запись найдена, возвращаем ключ (название enum)
  if (matchingEntry) {
    return matchingEntry[0] as string;
  }

  // Если запись не найдена, возвращаем undefined
  return undefined;
}