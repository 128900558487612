import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter" }
const _hoisted_2 = { class: "source-data-interval-button-wrapper" }
const _hoisted_3 = { class: "mr-3" }
const _hoisted_4 = { class: "date-wrapper mr-3" }
const _hoisted_5 = { class: "interval-wrapper" }
const _hoisted_6 = { class: "button-wrapper" }
const _hoisted_7 = { class: "logs-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_source_component = _resolveComponent("select-source-component")!
  const _component_tirscript3_date_picker = _resolveComponent("tirscript3-date-picker")!
  const _component_tirscript3_checkbox = _resolveComponent("tirscript3-checkbox")!
  const _component_select_interval_component = _resolveComponent("select-interval-component")!
  const _component_tirscript3_button = _resolveComponent("tirscript3-button")!
  const _component_logs_view_filter = _resolveComponent("logs-view-filter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createVNode(_component_select_source_component, {
          filterDefault: _ctx.innerFilter,
          onOnChangeSource: _ctx.changeSource,
          style: {"min-width":"350px","max-width":"fit-content"}
        }, null, 8, ["filterDefault", "onOnChangeSource"])
      ], 512), [
        [_vShow, _ctx.slectSource]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createVNode(_component_tirscript3_date_picker, {
          modelValue: _ctx.innerFilter.DateFrom,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerFilter.DateFrom) = $event)),
          changeDateManual: "true",
          dateFormat: "DD.MM.YYYY HH:mm:ss",
          initialView: "days",
          maximumView: "years",
          minimumView: "minutes"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_tirscript3_date_picker, {
          modelValue: _ctx.innerFilter.DateTo,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerFilter.DateTo) = $event)),
          changeDateManual: "true",
          dateFormat: "DD.MM.YYYY HH:mm:ss",
          initialView: "days",
          maximumView: "years",
          minimumView: "minutes"
        }, null, 8, ["modelValue"])
      ], 512), [
        [_vShow, _ctx.selectData]
      ]),
      _withDirectives(_createVNode(_component_tirscript3_checkbox, { onChange: _ctx.onLiveReload }, {
        default: _withCtx(() => [
          _createTextVNode(" Live Reload ")
        ]),
        _: 1
      }, 8, ["onChange"]), [
        [_vShow, _ctx.liveReload]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_5, [
        _createVNode(_component_select_interval_component, { onOnChangeInterval: _ctx.changeInterval }, null, 8, ["onOnChangeInterval"])
      ], 512), [
        [_vShow, _ctx.selectInterval]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_6, [
        _createVNode(_component_tirscript3_button, {
          style: {},
          onOnClick: _ctx.filterData
        }, {
          default: _withCtx(() => [
            _createTextVNode("Показать")
          ]),
          _: 1
        }, 8, ["onOnClick"])
      ], 512), [
        [_vShow, _ctx.selectButton]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_7, [
      _createVNode(_component_logs_view_filter, {
        loggerName: _ctx.loggerName,
        logsLevel: _ctx.logsLevel,
        logsType: _ctx.logsType,
        methods: _ctx.methods,
        requestId: _ctx.requestId,
        shortMessage: _ctx.shortMessage,
        url: _ctx.url,
        sessionToken: _ctx.sessionToken,
        ip: _ctx.ip,
        userId: _ctx.userId,
        connectionId: _ctx.connectionId,
        defaultFilter: _ctx.filter,
        projectLogGroupId: _ctx.projectLogGroupId,
        onOnFilter: _ctx.filterLogs
      }, null, 8, ["loggerName", "logsLevel", "logsType", "methods", "requestId", "shortMessage", "url", "sessionToken", "ip", "userId", "connectionId", "defaultFilter", "projectLogGroupId", "onOnFilter"])
    ], 512), [
      [_vShow, _ctx.selectLogsFilter]
    ])
  ]))
}