import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import GetWebLogRequest from '../../../logger-micro-service/services/models/request/web-getter-service/GetWebLogRequest';
import WsResponseModelT from '../../../schema-builder-server/models/WsResponseModelT';
import GetWebSocketLogsResponse from '../../../logger-micro-service/services/models/response/websocket-getter-service/GetWebSocketLogsResponse';
import GetWebsocketLogDetailtResponse from '../../../logger-micro-service/services/models/request/websocket-getter-service/GetWebsocketLogDetailtResponse';

export default class WebSocketGetterService {
	webApiService: WebApiService;
	GetLogsEvent = new Events<WsResponseModelT<GetWebSocketLogsResponse>>();
	GetLogDetailsEvent = new Events<WsResponseModelT<GetWebsocketLogDetailtResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('WebSocketGetterService', 'GetLogs', (res: WsResponseModelT<GetWebSocketLogsResponse>) => {
			self.GetLogsEvent.trigger(res);
		});
		webApiService.on('WebSocketGetterService', 'GetLogDetails', (res: WsResponseModelT<GetWebsocketLogDetailtResponse>) => {
			self.GetLogDetailsEvent.trigger(res);
		});
	}
	GetLogs(request: GetWebLogRequest) { 	
        this.webApiService.send('WebSocketGetterService', 'GetLogs' , request);
    }
	GetLogDetails(requestId: string) { 	
        this.webApiService.send('WebSocketGetterService', 'GetLogDetails' , requestId);
    }
	 // async methods
	async getLogsAsync(request: GetWebLogRequest): Promise<GetWebSocketLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogs.bind(this), this.GetLogsEvent)(request);
	}
	async getLogDetailsAsync(requestId: string): Promise<GetWebsocketLogDetailtResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogDetails.bind(this), this.GetLogDetailsEvent)(requestId);
	}
}