import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = {
  class: "align-self-center bold add-team-user-pop pl-3 p-2",
  style: {"font-weight":"bold","font-size":"17px"}
}
const _hoisted_3 = { style: {"font-size":"14px"} }
const _hoisted_4 = {
  key: 0,
  class: "d-flex justify-content-end py-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tirscript3_button = _resolveComponent("tirscript3-button")!
  const _component_tirscript3_modal_window = _resolveComponent("tirscript3-modal-window")!

  return (_openBlock(), _createBlock(_component_tirscript3_modal_window, {
    visible: _ctx.visible,
    width: '600px',
    title: 'Удаление',
    onClose: _ctx.onClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.dialogModal.Title), 1)
      ])
    ]),
    button: _withCtx(() => [
      (_ctx.dialogModal.Buttons)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dialogModal.Buttons, (item, index) => {
              return (_openBlock(), _createBlock(_component_tirscript3_button, {
                key: index,
                width: 120,
                class: "ml-2",
                style: _normalizeStyle([{"padding":"10px 25px","width":"fit-content","font-size":"14px"}, _ctx.getButtonStyle(item)]),
                onOnClick: ($event: any) => (_ctx.clickButton(item.Value))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.Title), 1)
                ]),
                _: 2
              }, 1032, ["style", "onOnClick"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.dialogModal.Text), 1)
    ]),
    _: 1
  }, 8, ["visible", "onClose"]))
}