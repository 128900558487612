import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import GetWebLogRequest from '../../../logger-micro-service/services/models/request/web-getter-service/GetWebLogRequest';
import GetActiveWebLogsRequest from '../../../logger-micro-service/services/models/request/web-getter-service/GetActiveWebLogsRequest';
import WsResponseModelT from '../../../schema-builder-server/models/WsResponseModelT';
import GetWebLogsResponse from '../../../logger-micro-service/services/models/response/web-getter-service/GetWebLogsResponse';
import GetWebLogDetailResponse from '../../../logger-micro-service/services/models/request/web-getter-service/GetWebLogDetailResponse';
import GetActiveWebLogsResponse from '../../../logger-micro-service/services/models/response/web-getter-service/GetActiveWebLogsResponse';
import GetLogStartDetailsResponse from '../../../logger-micro-service/services/models/response/web-getter-service/GetLogStartDetailsResponse';

export default class WebLogGetterService {
	webApiService: WebApiService;
	GetLogsEvent = new Events<WsResponseModelT<GetWebLogsResponse>>();
	GetLogDetailsEvent = new Events<WsResponseModelT<GetWebLogDetailResponse>>();
	GetActiveLogsEvent = new Events<WsResponseModelT<GetActiveWebLogsResponse>>();
	GetLogStartDetailsEvent = new Events<WsResponseModelT<GetLogStartDetailsResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('WebLogGetterService', 'GetLogs', (res: WsResponseModelT<GetWebLogsResponse>) => {
			self.GetLogsEvent.trigger(res);
		});
		webApiService.on('WebLogGetterService', 'GetLogDetails', (res: WsResponseModelT<GetWebLogDetailResponse>) => {
			self.GetLogDetailsEvent.trigger(res);
		});
		webApiService.on('WebLogGetterService', 'GetActiveLogs', (res: WsResponseModelT<GetActiveWebLogsResponse>) => {
			self.GetActiveLogsEvent.trigger(res);
		});
		webApiService.on('WebLogGetterService', 'GetLogStartDetails', (res: WsResponseModelT<GetLogStartDetailsResponse>) => {
			self.GetLogStartDetailsEvent.trigger(res);
		});
	}
	GetLogs(request: GetWebLogRequest) { 	
        this.webApiService.send('WebLogGetterService', 'GetLogs' , request);
    }
	GetLogDetails(requestId: string) { 	
        this.webApiService.send('WebLogGetterService', 'GetLogDetails' , requestId);
    }
	GetActiveLogs(request: GetActiveWebLogsRequest) { 	
        this.webApiService.send('WebLogGetterService', 'GetActiveLogs' , request);
    }
	GetLogStartDetails(requestId: string) { 	
        this.webApiService.send('WebLogGetterService', 'GetLogStartDetails' , requestId);
    }
	 // async methods
	async getLogsAsync(request: GetWebLogRequest): Promise<GetWebLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogs.bind(this), this.GetLogsEvent)(request);
	}
	async getLogDetailsAsync(requestId: string): Promise<GetWebLogDetailResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogDetails.bind(this), this.GetLogDetailsEvent)(requestId);
	}
	async getActiveLogsAsync(request: GetActiveWebLogsRequest): Promise<GetActiveWebLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetActiveLogs.bind(this), this.GetActiveLogsEvent)(request);
	}
	async getLogStartDetailsAsync(requestId: string): Promise<GetLogStartDetailsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLogStartDetails.bind(this), this.GetLogStartDetailsEvent)(requestId);
	}
}