import { WebApiService } from '../../plugins/webApiService'
import { Events } from '../../plugins/events'
import EventToAsyncDecorator from '../../plugins/eventToAsyncDecorator'
import UsersWithMaxRequestsCountRequest from '../../analytic-users/i-service/models/request/UsersWithMaxRequestsCountRequest';
import GetCountEndPointsByUserIdRequest from '../../analytic-users/i-service/models/request/GetCountEndPointsByUserIdRequest';
import ActiveUsersRequest from '../../analytic-users/i-service/models/request/ActiveUsersRequest';
import UsersWithTheirEndpointsRequest from '../../analytic-users/i-service/models/request/UsersWithTheirEndpointsRequest';
import WsResponseModelT from '../../schema-builder-server/models/WsResponseModelT';
import UsersWithMaxEndpointRequestsCountResponse from '../../analytic-users/i-service/models/response/UsersWithMaxEndpointRequestsCountResponse';
import GetCountEndPointsByUserIdGroupedResponse from '../../analytic-users/i-service/models/response/GetCountEndPointsByUserIdGroupedResponse';
import ActiveUsersResponse from '../../analytic-users/i-service/models/response/ActiveUsersResponse';
import UsersWithTheirEndpointsResponse from '../../analytic-users/i-service/models/response/UsersWithTheirEndpointsResponse';

export default class AnalyticUsersService {
	webApiService: WebApiService;
	GetUsersWithMaxEndpointRequestsCountEvent = new Events<WsResponseModelT<Array<UsersWithMaxEndpointRequestsCountResponse>>>();
	GetCountEndPointsByUserIdEvent = new Events<WsResponseModelT<Array<GetCountEndPointsByUserIdGroupedResponse>>>();
	GetActiveUsersEvent = new Events<WsResponseModelT<Array<ActiveUsersResponse>>>();
	GetUsersWithTheirEndpointsEvent = new Events<WsResponseModelT<UsersWithTheirEndpointsResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('AnalyticUsersService', 'GetUsersWithMaxEndpointRequestsCount', (res: WsResponseModelT<Array<UsersWithMaxEndpointRequestsCountResponse>>) => {
			self.GetUsersWithMaxEndpointRequestsCountEvent.trigger(res);
		});
		webApiService.on('AnalyticUsersService', 'GetCountEndPointsByUserId', (res: WsResponseModelT<Array<GetCountEndPointsByUserIdGroupedResponse>>) => {
			self.GetCountEndPointsByUserIdEvent.trigger(res);
		});
		webApiService.on('AnalyticUsersService', 'GetActiveUsers', (res: WsResponseModelT<Array<ActiveUsersResponse>>) => {
			self.GetActiveUsersEvent.trigger(res);
		});
		webApiService.on('AnalyticUsersService', 'GetUsersWithTheirEndpoints', (res: WsResponseModelT<UsersWithTheirEndpointsResponse>) => {
			self.GetUsersWithTheirEndpointsEvent.trigger(res);
		});
	}
	GetUsersWithMaxEndpointRequestsCount(request: UsersWithMaxRequestsCountRequest) { 	
        this.webApiService.send('AnalyticUsersService', 'GetUsersWithMaxEndpointRequestsCount' , request);
    }
	GetCountEndPointsByUserId(request: GetCountEndPointsByUserIdRequest) { 	
        this.webApiService.send('AnalyticUsersService', 'GetCountEndPointsByUserId' , request);
    }
	GetActiveUsers(request: ActiveUsersRequest) { 	
        this.webApiService.send('AnalyticUsersService', 'GetActiveUsers' , request);
    }
	GetUsersWithTheirEndpoints(request: UsersWithTheirEndpointsRequest) { 	
        this.webApiService.send('AnalyticUsersService', 'GetUsersWithTheirEndpoints' , request);
    }
	 // async methods
	async getUsersWithMaxEndpointRequestsCountAsync(request: UsersWithMaxRequestsCountRequest): Promise<Array<UsersWithMaxEndpointRequestsCountResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetUsersWithMaxEndpointRequestsCount.bind(this), this.GetUsersWithMaxEndpointRequestsCountEvent)(request);
	}
	async getCountEndPointsByUserIdAsync(request: GetCountEndPointsByUserIdRequest): Promise<Array<GetCountEndPointsByUserIdGroupedResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCountEndPointsByUserId.bind(this), this.GetCountEndPointsByUserIdEvent)(request);
	}
	async getActiveUsersAsync(request: ActiveUsersRequest): Promise<Array<ActiveUsersResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetActiveUsers.bind(this), this.GetActiveUsersEvent)(request);
	}
	async getUsersWithTheirEndpointsAsync(request: UsersWithTheirEndpointsRequest): Promise<UsersWithTheirEndpointsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetUsersWithTheirEndpoints.bind(this), this.GetUsersWithTheirEndpointsEvent)(request);
	}
}