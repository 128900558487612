
import Api from "@/dataSource/api/apiDataSource";


declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $api: Api;// ApiDataSource;
  }
}


const install = (app: any, opts: { url: string }) => {
  if (!(opts || {}).url) {
    console.error('Для работы api плагина необходимо задать параметр url!');
    return;
  }
  // const apiDataSource = new ApiDataSource(opts.url);
  const apiDataSource = new Api(opts.url);
  apiDataSource.webApiService.init();
  app.config.globalProperties.$api = apiDataSource;
  // app.config.globalProperties.$store = store;
  //console.log('api data source: ', apiDataSource);

};

const dialog = {

  install,
};

export default dialog;