import { WebApiService } from '../../../plugins/webApiService'
import { Events } from '../../../plugins/events'
import EventToAsyncDecorator from '../../../plugins/eventToAsyncDecorator'
import GetTopWebLogsRequest from '../../../logger-micro-service/services/models/request/web-getter-service/GetTopWebLogsRequest';
import WsResponseModelT from '../../../schema-builder-server/models/WsResponseModelT';
import GetTopWebLogsResponse from '../../../logger-micro-service/services/models/response/web-getter-service/GetTopWebLogsResponse';

export default class TopWebLogsService {
	webApiService: WebApiService;
	GetTopLongRequestsEvent = new Events<WsResponseModelT<GetTopWebLogsResponse>>();
	GetTopFatRequestsEvent = new Events<WsResponseModelT<GetTopWebLogsResponse>>();
	GetTopFatResponsesEvent = new Events<WsResponseModelT<GetTopWebLogsResponse>>();
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('TopWebLogsService', 'GetTopLongRequests', (res: WsResponseModelT<GetTopWebLogsResponse>) => {
			self.GetTopLongRequestsEvent.trigger(res);
		});
		webApiService.on('TopWebLogsService', 'GetTopFatRequests', (res: WsResponseModelT<GetTopWebLogsResponse>) => {
			self.GetTopFatRequestsEvent.trigger(res);
		});
		webApiService.on('TopWebLogsService', 'GetTopFatResponses', (res: WsResponseModelT<GetTopWebLogsResponse>) => {
			self.GetTopFatResponsesEvent.trigger(res);
		});
	}
	GetTopLongRequests(request: GetTopWebLogsRequest) { 	
        this.webApiService.send('TopWebLogsService', 'GetTopLongRequests' , request);
    }
	GetTopFatRequests(request: GetTopWebLogsRequest) { 	
        this.webApiService.send('TopWebLogsService', 'GetTopFatRequests' , request);
    }
	GetTopFatResponses(request: GetTopWebLogsRequest) { 	
        this.webApiService.send('TopWebLogsService', 'GetTopFatResponses' , request);
    }
	 // async methods
	async getTopLongRequestsAsync(request: GetTopWebLogsRequest): Promise<GetTopWebLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetTopLongRequests.bind(this), this.GetTopLongRequestsEvent)(request);
	}
	async getTopFatRequestsAsync(request: GetTopWebLogsRequest): Promise<GetTopWebLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetTopFatRequests.bind(this), this.GetTopFatRequestsEvent)(request);
	}
	async getTopFatResponsesAsync(request: GetTopWebLogsRequest): Promise<GetTopWebLogsResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.GetTopFatResponses.bind(this), this.GetTopFatResponsesEvent)(request);
	}
}